import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserType } from '../../types/authentication';
import { Employee } from 'src/types/employee';
import { clearTokens } from 'src/lib/token';

interface InitialState {
  employees: Employee[];
  userDetails: UserType | undefined;
}

const initialState: InitialState = {
  employees: [],
  userDetails: undefined,
};

const User = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    saveUserDetails: (state, action: PayloadAction<UserType>) => {
      return { ...state, userDetails: action.payload };
    },
    setEmployees(state, action: PayloadAction<Employee[]>) {
      return { ...state, employees: action.payload };
    },
    logout: () => {
      clearTokens();
      return initialState;
    },
  },
});

export const { saveUserDetails, logout, setEmployees } = User.actions;
export default User.reducer;
