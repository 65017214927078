import axios from 'axios';
import { getAccessToken, clearTokens } from './../../lib/token';
import { refreshAccessToken } from '../services/refreshAccessToken';

const baseURL = 'https://api.workintercept.com';

const apiClient = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const protectedApiClient = axios.create({
  baseURL,
});

// Attach token dynamically before each request
protectedApiClient.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Handle token expiration and 401 error retry requests
protectedApiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();
        if (newAccessToken) {
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return protectedApiClient(originalRequest); // Retry request with new token
        }
      } catch (refreshError) {
        clearTokens(); // Clear tokens and logout user if refresh fails
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export { apiClient, protectedApiClient };
