import React, { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { Button } from 'src/components/ui/button';
import { Settings2 } from 'lucide-react';
import SelectField from 'src/components/shared/SelectField';
import { RootState, useAppSelector } from 'src/redux/store';
import { useGetDepartments } from 'src/api/hooks/department';
import { Option } from 'src/components/shared/FormFieldGenerator';
import { accessLevel, status } from 'src/constant/data';
import { Table } from '@tanstack/react-table';
import Search from '../shared/Search';

interface EmployeeToolbarProps<TData> {
  table: Table<TData>;
}

const InitialState = {
  status: '',
  department: '',
  access: '',
};

interface EmployeeFiltersState {
  department?: string;
  status?: string;
  access?: string;
}

const EmployeeToolbar = <TData,>({ table }: EmployeeToolbarProps<TData>) => {
  const companyId = useAppSelector((state: RootState) => state.user.userDetails?.companyId) || '';
  const { data: departments, isLoading } = useGetDepartments(companyId);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [filters, setFilters] = useState<EmployeeFiltersState>(InitialState);

  const statusColumn = table.getColumn('status');
  const departmentColumn = table.getColumn('department');
  const accessColumn = table.getColumn('accessLevel');

  const applyFilters = () => {
    if (statusColumn && filters.status) {
      statusColumn?.setFilterValue(filters.status);
    } else {
      statusColumn?.setFilterValue(undefined);
    }

    if (departmentColumn && filters.department) {
      departmentColumn?.setFilterValue(filters.department);
    } else {
      departmentColumn?.setFilterValue(undefined);
    }

    if (accessColumn && filters.access) {
      accessColumn?.setFilterValue(filters.access);
    } else {
      accessColumn?.setFilterValue(undefined);
    }
    setIsPopoverOpen(false);
  };

  const resetFilters = () => {
    setFilters(InitialState);
    statusColumn?.setFilterValue(undefined);
    accessColumn?.setFilterValue(undefined);
    departmentColumn?.setFilterValue(undefined);
    setIsPopoverOpen(false);
  };

  return (
    <div className="mb-4 flex justify-between">
      <Search />
      <div>
        <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <PopoverTrigger asChild>
            <Button variant="outline" className="px-4 py-2">
              <span className="mr-2">
                <Settings2 size={20} />
              </span>
              Filter
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-full px-3 py-4 sm:w-[212px]" align="end">
            <SelectField
              label="Department"
              placeholder="Select Department"
              options={departments?.map((department: Option) => department)}
              loading={isLoading}
              onChange={(value) => setFilters({ ...filters, department: value || '' })}
              value={filters.department}
            />
            <SelectField
              label="Status"
              placeholder="Select Status"
              options={status}
              onChange={(value) => setFilters({ ...filters, status: value || '' })}
              value={filters.status}
            />
            <SelectField
              label="Access"
              placeholder="Select Access"
              options={accessLevel}
              onChange={(value) => setFilters({ ...filters, access: value || '' })}
              value={filters.access}
            />
            <div className="mt-3 flex justify-between">
              <Button variant="outline" className="hover:bg-white" onClick={resetFilters}>
                Reset
              </Button>
              <Button
                onClick={applyFilters}
                className="bg-primary-500 px-5 py-2 text-white hover:bg-primary-500/90"
              >
                Apply
              </Button>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default EmployeeToolbar;
