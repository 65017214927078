import IndeterminateCheckbox from 'src/components/shared/IndeterminateCheckbox';
import { ChevronsUpDown, CircleCheck, CircleX, EllipsisVertical, Trash } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { ColumnDef } from '@tanstack/react-table';
import Drawer from 'src/components/shared/Drawer';
import React, { useState } from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { EyeIcon } from 'lucide-react';
import { UserInfo } from '../shared/UserInfo';
import { Button } from '../ui/button';
import { RootState, useAppSelector } from 'src/redux/store';

import DeleteModal from '../shared/Modal/DeleteModal';
import { Leave, LeaveStatus } from 'src/types/leave';
import { formatDateRange, getDaysDifference, getFirstLetters } from 'src/lib/utils';
import { useDeleteLeave, useGetCompanyLeaves, useUpdateLeaveStatus } from 'src/api/hooks/leave';
import DataTable from '../shared/DataTable';
import LeaveToolbar from './LeaveToolbar';

const LeaveTable = () => {
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState<string>('');
  const [leveaDetails, setLeavedetails] = useState<any>({
    imgUrl: '',
    role: '',
    firstName: '',
    lastName: '',
  });

  const companyId = useAppSelector((state: RootState) => state.user.userDetails?.companyId) || '';
  const { handleDelete } = useDeleteLeave();
  const { handleUpdateStatus } = useUpdateLeaveStatus();
  const { data, isLoading } = useGetCompanyLeaves(companyId);

  const leaveStatus = (leave: Leave) => {
    switch (leave.status) {
      case LeaveStatus.PENDING:
        return (
          <div className="flex space-x-2">
            <CircleCheck
              onClick={() => handleUpdateStatus({ id: leave._id, status: LeaveStatus.APPROVED })}
              color="#0D824B"
              className="cursor-pointer"
              role="button"
            />
            <CircleX
              onClick={() => handleUpdateStatus({ id: leave._id, status: LeaveStatus.REJECTED })}
              color="#AA3028"
              className="cursor-pointer"
              role="button"
            />
          </div>
        );

      case LeaveStatus.APPROVED:
        return <span className="status-bar bg-green-100 text-green-800">Approved</span>;

      case LeaveStatus.REJECTED:
        return <span className="status-bar bg-red-100 text-red-800">Rejected</span>;
      default:
        return null;
    }
  };

  const columns = React.useMemo<ColumnDef<Leave>[]>(
    () => [
      {
        accessorKey: 'name',
        header: ({ table }) => (
          <div className="flex items-center px-1">
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
            <span className="ml-3">Name</span>
            <ChevronsUpDown />
          </div>
        ),
        cell: ({ row }) => {
          const { user: { firstName = '', lastName = '', imgUrl } = {} } = row.original;
          const name = `${firstName} ${lastName}`;
          return (
            <div className="flex items-center px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
              <Avatar className="mx-3">
                <AvatarImage src={imgUrl ?? ''} alt="employee" />
                <AvatarFallback>{getFirstLetters(name)}</AvatarFallback>
              </Avatar>
              <p className="whitespace-nowrap text-sm">{name}</p>
            </div>
          );
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'duration',
        header: () => <span>Duration</span>,
        cell: ({
          row: {
            original: { startDate, endDate },
          },
        }) => {
          const numberOfdays = getDaysDifference(startDate, endDate);
          return (
            <span>
              {numberOfdays} {numberOfdays > 1 ? 'days' : 'day'}
            </span>
          );
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'date',
        header: 'Date',
        cell: ({
          row: {
            original: { startDate, endDate },
          },
        }) => (
          <span className="whitespace-nowrap text-sm">{formatDateRange(startDate, endDate)}</span>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'reason',
        header: 'Type',
      },

      {
        accessorKey: 'status',
        header: 'Approval',
        cell: ({ row }) => leaveStatus(row.original),
      },
      {
        id: 'actions',
        cell: ({ row }) => (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <button className="flex h-8 w-8 items-center justify-center rounded-md border border-neutral-50">
                <EllipsisVertical className="h-4 w-4" />
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[147px]">
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={() => {
                  row.original.user && setLeavedetails(row?.original);
                  setOpenDetails(true);
                }}
              >
                <EyeIcon />
                View Detail
              </DropdownMenuItem>
              <DropdownMenuItem
                className="cursor-pointer text-error-700 focus:text-error-700"
                onClick={() => setSelectedRow(row.original._id ?? '')}
              >
                <Trash />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
        enableHiding: false,
        footer: (props) => props.column.id,
      },
    ],
    [],
  );

  const {
    _id,
    role = '',
    startDate,
    endDate,
    reason,
    comments,
    employee: { firstName = '', lastName = '', imgUrl = '' } = {},
    status,
  } = leveaDetails;

  const closeDeleteModal = () => setSelectedRow('');
  const closeDrawer = () => setOpenDetails(false);

  const numberOfdays = getDaysDifference(startDate, endDate);

  return (
    <>
      <DataTable
        columns={columns}
        data={data || []}
        loading={isLoading}
        toolbar={({ table }) => <LeaveToolbar table={table as any} />}
      />
      <Drawer
        open={openDetails}
        setOpen={setOpenDetails}
        className="gap-0"
        header={{
          title: 'Leave Details',
          description: 'Leave details of employee who request for it.',
        }}
      >
        <div className="flex h-full flex-col">
          <div className="mb-4 flex items-center border-b px-6 py-4">
            <UserInfo
              className="border-t-0 pl-0"
              user={{
                identity: role || 'UI developer',
                name: `${firstName} ${lastName}`,
                avatar: imgUrl || '',
              }}
            />
          </div>
          <div className="mt-4 flex flex-1 flex-col px-6">
            <div className="flex-1">
              <div className="mb-4">
                <h2 className="text-sm text-neutral-300">Date</h2>
                <p className="text-base font-semibold text-neutral-900">
                  {' '}
                  {formatDateRange(startDate, endDate)}
                </p>
              </div>
              <div className="mb-4">
                <h2 className="text-sm text-neutral-300">Leave Type</h2>
                <p className="text-base font-semibold text-neutral-900">{reason}</p>
              </div>
              <div className="mb-4">
                <h2 className="text-sm text-neutral-300">Duration</h2>
                <p className="text-base font-semibold text-neutral-900">
                  {numberOfdays} {numberOfdays > 1 ? 'days' : 'day'}
                </p>
              </div>
              <div className="mb-4">
                <h2 className="text-sm text-neutral-300">Reason for leave</h2>
                <p className="text-base font-semibold text-neutral-900">{comments ?? '-'}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-3 border-t p-6">
            {status !== LeaveStatus.REJECTED && (
              <Button
                onClick={() => {
                  handleUpdateStatus({ id: _id, status: LeaveStatus.REJECTED });
                  closeDrawer();
                }}
                variant="outline"
                className="rounded-md border border-error-500 px-4 py-2 text-error-500 hover:bg-red-100 hover:text-error-500"
              >
                Reject
              </Button>
            )}
            {status !== LeaveStatus.APPROVED && (
              <Button
                onClick={() => {
                  handleUpdateStatus({ id: _id, status: LeaveStatus.APPROVED });
                  closeDrawer();
                }}
                type="button"
              >
                Approve
              </Button>
            )}
          </div>
        </div>
      </Drawer>
      <DeleteModal
        description="Are you sure you want to delete this employee’s Leave ? This action can not be undone."
        title="Delete Leave"
        open={!!selectedRow}
        setOpen={setSelectedRow as any}
        handleDelete={() => {
          handleDelete(selectedRow);
          closeDeleteModal();
        }}
        handleCancel={closeDeleteModal}
      />
    </>
  );
};

export default LeaveTable;
